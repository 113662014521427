import { useSelector } from 'react-redux';
import Loading from '../../reusableElementsComponents/Loading';
import mazharLogo from '../../../assets/images/logos/mazhr-beta-logo-white.svg'


function MobileAuthHeader({title, secondaryTitle, subTitle, illustration, logo}) {
    const data = useSelector( state => state.contentfulData.all['Auth mobile decoration']);

    if(data !== undefined) {
        return (
            <div className="col-12 d-lg-none auth-mobile-header p-0">
                
                <div className="title-wrapper">
                    <h1 className="title l semi-bold">{title}</h1>

                    {logo === true ? <img className="logo" src={mazharLogo} alt="Mazhr" /> : 'MazHr' }

                    <h1 className="title semi-bold">{secondaryTitle}</h1>

                    {illustration ? <img src={illustration} alt="mazhr" /> : ''}

                    <p className="sub-title">{subTitle}</p>
                </div>
                <div className="decoration-wrapper">
                    <img className="blob lighter" src={data.images.mobileBlob} alt="blob" />
                    <img className="blob darker" src={data.images.mobileBlob} alt="blob" />
                </div>
            </div>
        )
    }else {        
        return (
            <Loading />
        )
    }
}

export default MobileAuthHeader;
