import B2BApi from './B2bApi';
import * as Constants from "./constants";
import {hasEmptyProperty} from "./Utilities";
import {store} from './store/store';
import {setJobPositionData} from './store/actions/positionDataActions'
import {setTeamData} from './store/actions/teamDataActions'
import {setCompanyInfo, setCompanyStatus} from "./store/actions/companyActions";

export default class B2BApiService {
    b2bApiObj = new B2BApi();

    Login = (data) => {
        return this.b2bApiObj.apiCall('auth/login/', 'post', JSON.stringify(data))
    }

    CreateNewUserAccount = (userData, callBackFunction) => {
        this.b2bApiObj.apiCall('company/user/', 'POST', JSON.stringify(userData)).then(res => {
            if (res.httpStatus === 200 && res.json && res.json.data) {
                callBackFunction({
                    httpStatus : res.httpStatus,
                    id : res.json.data.id,
                    email: res.json.data.email,
                    first: res.json.data.first_name,
                    last: res.json.data.last_name,
                    role_id: res.json.data.role_id,
                    anonymous_results : res.json.data.anonymous_results
                });
            }else{
                callBackFunction(res)
            }
        });
    }

    DeleteUserAccount = (userId, callBackFunction) => {
        this.b2bApiObj.apiCall(`company/user/${userId}`, 'DELETE').then((res) => {
            callBackFunction(res); // Callback without any data on success
        });
    };

    GetCompanyAccounts = (callBackFunction) => {
        this.b2bApiObj.apiCall('company/user/', 'get').then(res => {
            if (res.httpStatus === 200 && res.json && res.json.data) {
                callBackFunction(res.json.data);
            }
        });
    }

    GetRoleConfigById = (configId, callBackFunction) =>{
        this.b2bApiObj.apiCall('user-role-config/' + configId, 'get').then(res => {
            if (res.httpStatus === 200 && res.json && res.json.data) {
                callBackFunction(res.json.data);
            }
        });
    }

    GetCompanyAccountsByRoleId = (roleId,callBackFunction) => {
        this.b2bApiObj.apiCall('company/user/role/' + roleId, 'get').then(res => {
            if (res.httpStatus === 200 && res.json && res.json.data) {
                callBackFunction(res.json.data);
            }
        });
    }

    LoadUserData = (setDataFunction) => {
        this.b2bApiObj.apiCall('user/info/', 'get').then(res => {
            if (res.httpStatus === 200 && res.json && res.json.data) {
                setDataFunction({
                    email: res.json.data.email,
                    first: res.json.data.first_name,
                    last: res.json.data.last_name,
                    userRole: res.json.data.role_id,
                    anonymous_results : res.json.data.anonymous_results
                });
            }
        });
    }

    LoadCompanyData = (setDataAction = null) => {
        this.b2bApiObj.apiCall('company/info/', 'get').then(res => {
            if (res.httpStatus === 200 && res.json && res.json.data) {
                if (setDataAction) {
                    setDataAction({
                        name: res.json.data.name,
                        employee_count: res.json.data.employee_count,
                        industry: res.json.data.industry,
                        contact_phone: res.json.data.contact_phone,
                        logo: res.json.data.logo,
                        is_active: res.json.data.is_active
                    });
                }

                store.dispatch(setCompanyInfo(
                    {
                        name: res.json.data.name,
                        employee_count: res.json.data.employee_count,
                        industry: res.json.data.industry,
                        contact_phone: res.json.data.contact_phone,
                        logo: res.json.data.logo,
                    }
                ))
                store.dispatch(setCompanyStatus(res.json.data.is_active))
            }
        });
    }

    SaveUserData = (data) => {
        if (!hasEmptyProperty(data, ['first', 'last', 'email'])) {
            this.b2bApiObj.apiCall('user/info/', 'put', JSON.stringify(data));
        } else {
            return false;
        }
    }

    SaveCompanyData = (data, callBackFunct) => {

        if (hasEmptyProperty(data, ['company_name', 'employee_count', 'contact_phone', 'industry_id'])) {
            return false;
        }
        data.industry_id = (data?.industry_id).toString()
        this.b2bApiObj.apiCall('company/info/', 'put', JSON.stringify(data)).then(res => {
            if (res.httpStatus === 200) {
                callBackFunct(res.json)


            } else {
                callBackFunct(null)
            }
        });
    }

    LoadIndustriesList = (setDataFunction) => {
        this.b2bApiObj.apiCall('job-industries/', 'get').then(res => {
            if (res.httpStatus === 200) {
                let industryData = [];
                res.json.map(data => (
                    industryData.push({
                        "id": data.id,
                        "title": data.name
                    })
                ))

                setDataFunction(industryData)
            }
        });
    }

    LoadTargetProfilesByIndustry = (jobIndustryObj, setDataFunction) => {
        this.b2bApiObj.apiCall('target-profiles/' + jobIndustryObj.id, 'get').then(res => {
            setDataFunction(res.json)
        });
    }

    SavePool = (JsonData, callbackFunction, onFailCallBack) => {
        this.b2bApiObj.apiCall('pool/', 'post', JsonData)
            .then(response => {
                callbackFunction(response)
            }).catch(() => {
              onFailCallBack()
        })
    }

    UpdateUserAccessToPools = (userId, poolHashes, callBackFunction) => {
        this.b2bApiObj.apiCall('pool-access/', 'post', JSON.stringify({'user_id' : userId, 'pool_hashes' :poolHashes }))
            .then(response => {
                if (response.ok && response.httpStatus === 200 && response.json) {
                    callBackFunction(response.json)
                } else {
                    callBackFunction(response)
                }
            })
    }

    EmailConfirmation = (email) => {
        return this.b2bApiObj.apiCall('user/forgot_password/', 'post', JSON.stringify(email))

    }

    SetNewPassword = (obj) => {
        return this.b2bApiObj.apiCall('user/reset_password/', 'post', JSON.stringify(obj))
    }

    LoadPoolByHash = (hash, setDataFunction = null, setLoader) => {
        setLoader && setLoader(true);
        this.b2bApiObj.apiCall('pool/' + hash, 'get').then(res => {
            if (res.httpStatus === 200) {
                setLoader && setLoader(false);
                if (setDataFunction) {
                    setDataFunction(res.json.data[0]);
                }
                store.dispatch(setJobPositionData(res.json.data[0]))
            }
        })
    }

    LoadPoolsWithIdAndTitle = (setDataFunction) => {
        this.b2bApiObj.apiCall('pool/', 'get').then(res => {
            if (res.httpStatus === 200) {
                let poolData = [];
                res.json.data.map(data => (
                    poolData.push({
                        "id": data.hash,
                        "title": data.title.toUpperCase()
                    })
                ))
                setDataFunction(poolData)
            }
        });
    }

    LoadPoolsWithAllData = (setDataFunction, setLoader) => {
        setLoader && setLoader(true);
        this.b2bApiObj.apiCall('pool/', 'get').then(res => {
            if (res.httpStatus === 200) {
                setDataFunction(res.json.data);
                setLoader && setLoader(false);
            }
        });
    }

    SaveTeam = (data, callbackFunction) => {
        if (!hasEmptyProperty(data, ['title', 'industry_id', 'email_list', 'assessments'])) {
            this.b2bApiObj.apiCall('team/', 'post', JSON.stringify(data)).then(res => {
                if (res.httpStatus === 200 && res.json.data) {
                    callbackFunction(true)
                } else {
                    callbackFunction(false)
                }
            })
        } else {
            callbackFunction(false)
        }
    }

    UpdateUserAccessToTeams = (userId, teamHashes, callBackFunction) => {
        this.b2bApiObj.apiCall('team-access/', 'post', JSON.stringify({'user_id' : userId, 'team_hashes' :teamHashes }))
            .then(response => {
                if (response.ok && response.httpStatus === 200 && response.json) {
                    callBackFunction(response.json)
                } else {
                    callBackFunction(response)
                }
            })
    }

    UpdateUserAccessToTeamMembers = (userId, teamMemberIds, callBackFunction) => {
        this.b2bApiObj.apiCall('team-member-access/', 'post', JSON.stringify({'user_id' : userId, 'team_member_ids' :teamMemberIds }))
            .then(response => {
                if (response.ok && response.httpStatus === 200 && response.json) {
                    callBackFunction(response.json)
                } else {
                    callBackFunction(response)
                }
            })
    }

    UpdateTeam = (data, callbackFunction) => {
        if (!hasEmptyProperty(data, ['title', 'industry_id', 'assessments'])) {
            this.b2bApiObj.apiCall('team/', 'put', JSON.stringify(data)).then(res => {
                if (res.httpStatus === 200 && res.json.data) {
                    store.dispatch(setTeamData(res.json.data[0]))
                    callbackFunction(true, res)
                } else {
                    callbackFunction(false)
                }

            });
        } else {
            callbackFunction(false)
        }
    }

    LoadTeamByHash = (hash, setDataFunction = null, setLoader) => {
        setLoader && setLoader(true);
        this.b2bApiObj.apiCall('team/' + hash, 'get').then(res => {
            if (res.httpStatus === 200) {
                setLoader && setLoader(false);
                if (setDataFunction) {
                    setDataFunction(res.json.data[0]);
                }
                store.dispatch(setTeamData(res.json.data[0]))
            }
        })
    }

    LoadTeamMembersByCompanyId = (companyId, setDataFunction) => {
        this.b2bApiObj.apiCall('team-members/' + companyId.toString(), 'get').then(res => {
            if (res.httpStatus === 200) {
                setDataFunction(res.json.data)
            }
        });
    }

    LoadTeamsWithIdAndTitle = (setDataFunction) => {
        this.b2bApiObj.apiCall('team/', 'get').then(res => {
            if (res.httpStatus === 200) {
                let teamData = [];
                res.json.data.map(data => (
                    teamData.push({
                        "id": data.hash,
                        "title": data.title.toUpperCase()
                    })
                ))
                setDataFunction(teamData)
            }
        });
    }

    LoadTeamsWithAllData = (setDataFunction, setLoader) => {
        setLoader && setLoader(true);
        this.b2bApiObj.apiCall('team/', 'get').then(res => {
            if (res.httpStatus === 200) {
                setDataFunction(res.json.data);
                setLoader && setLoader(false);
            }
        });
    }

    LoadAssessments = (setDataFunction) => {
        this.b2bApiObj.apiCall('assessments/', 'get').then(res => {
            setDataFunction(res.json.data);
        });
    }


    SearchTalents = (setDataFunction, typeFilter, selectedJobsHashes, selectedTeamHashes, profileId = null, filtersKeyArray = []) => {
        let payload = {
            type: typeFilter
        }

        if (profileId !== "" && profileId !== undefined && profileId) {
            payload.targetProfileId = profileId
        }

        if (selectedJobsHashes.length > 0) {
            payload.poolHashes = selectedJobsHashes
        }

        if (selectedTeamHashes.length > 0) {
            payload.teamHashes = selectedTeamHashes
        }

        if (filtersKeyArray.length > 0) {
            payload.teamStrengths = filtersKeyArray[0] ? filtersKeyArray[0][Constants.TALENT_SEARCH_FILTER_TEAM_STRENGTHS] : {}
            payload.salesStrengths = filtersKeyArray[1] ? filtersKeyArray[1][Constants.TALENT_SEARCH_FILTER_SALES_STRENGTHS] : {}
            payload.motivationStrengths = filtersKeyArray[2] ? filtersKeyArray[2][Constants.TALENT_SEARCH_FILTER_MOTIVATION_FACTORS] : {}
            payload.competences = filtersKeyArray[3] ? filtersKeyArray[3][Constants.TALENT_SEARCH_FILTER_COMPETENCES] : {}
        }

        this.b2bApiObj.apiCall('candidate-search', 'post', JSON.stringify(payload)).then(data => {
            if (data.ok && data.json && data.json.data) {
                setDataFunction(data.json.data);
            }
        });
    }

    HireTalent = (email, teamHash, callBackFunc) => {

        this.b2bApiObj.apiCall('hire-talent/' + email + "/team/" + teamHash, 'post').then(data => {
            if (data.httpStatus === 200 && data.json) {
                callBackFunc(data.json);
            }
        });
    }

    InviteTalentToPool = (userId, isAnonymous, poolHash, callBackFunc) => {
        if (isAnonymous) {
            isAnonymous = 1;
        } else {
            isAnonymous = 2;
        }
        this.b2bApiObj.apiCall('invite-talent/' + userId + '/anonymous/' + isAnonymous + '/pool/' + poolHash, 'post').then(data => {
            if (data.httpStatus === 200 && data.json) {
                callBackFunc(data.json);
            }
        });
    }

    GetUserById = (id, asEmployee, callBack) => {
        let asEmployeeParam = 0
        if (asEmployee) {
            asEmployeeParam = 1
        }

        let payload = {
            id: id,
            employee: asEmployeeParam
        }

        this.b2bApiObj.apiCall('talent/get-details', 'POST', JSON.stringify(payload)).then(res => {
            if (res.httpStatus === 200 && res.json) {
                callBack(res.json)
            }
        });
    }
    GetUserByIdAndPoolHash = (id, hash, asEmployee, callBack) => {
        let asEmployeeParam = 0
        if (asEmployee) {
            asEmployeeParam = 1
        }

        let payload = {
            id: id,
            employee: asEmployeeParam,
            poolHash: hash
        }

        this.b2bApiObj.apiCall('talent/get-details', 'POST', JSON.stringify(payload)).then(res => {
            if (res.httpStatus === 200 && res.json) {
                callBack(res.json)
            }
        });
    }

    TransferPoolFromOrigin = (origin_hash, destination_hash, user_id, remove_from_origin, setLoader, pageReloader, setAddPoolModal, setErrorMessage) => {
        setLoader(true)
        let payload = {
            origin_hash: origin_hash,
            destination_hash: destination_hash,
            user_id: user_id,
            remove_from_origin: remove_from_origin
        }
        this.b2bApiObj.apiCall('move-candidate', 'POST', JSON.stringify(payload)).then(res => {
            if (res.httpStatus === 200 && res.json) {
                setErrorMessage("")
                setLoader(false)
                setAddPoolModal(false)
                pageReloader()
            }
        })
        .catch(error => {
            // Handle the error here
            setErrorMessage(error)
            // Perform your desired actions or show an error message
        });
    }

    GetUserByIdIndustryAndTargetProfileId = (id, industryId, targetProfileId, asEmployee, callBack) => {
        let asEmployeeParam = 0
        if (asEmployee) {
            asEmployeeParam = 1
        }

        let payload = {
            id: id,
            employee: asEmployeeParam,
            industryId: industryId,
            targetProfileId: targetProfileId
        }

        this.b2bApiObj.apiCall(
            'talent/get-details', 'POST', JSON.stringify(payload))
            .then(res => {
                    if (res.httpStatus === 200 && res.json) {
                        callBack(res.json)
                    }
                }
            );
    }

    EmailExist = (email, doesExistFunc) => {
        if (email === "" || email === undefined || !email) {
            doesExistFunc(false)
            return;
        }

        this.b2bApiObj.apiCall('exist/email/' + email, 'post').then(data => {
            if (data.httpStatus === 200 && data.json) {
                doesExistFunc(data.json.exist);
            }
        });

    }

    CompanyByNameExist = (companyName, doesExistFunc) => {
        if (companyName === "" || companyName === undefined || !companyName) {
            doesExistFunc(false)
            return;
        }

        this.b2bApiObj.apiCall('exist/company/' + companyName, 'post').then(data => {
            if (data.httpStatus === 200 && data.json) {
                doesExistFunc(data.json.exist);
            }
        });
    }

    PhoneNumberExist = (phoneNumber, doesExistFunc) => {
        if (phoneNumber === "" || phoneNumber === undefined || !phoneNumber) {
            doesExistFunc(false)
            return;
        }

        this.b2bApiObj.apiCall('exist/phone/' + phoneNumber, 'post').then(data => {
            if (data.httpStatus === 200 && data.json) {
                doesExistFunc(data.json.exist);
            }
        });
    }

    GetTeamStrengthList = (payload, definitions, setDataTableList, setAllCol, setLoader) => {
        this.b2bApiObj.apiCall('team-strength-report/', 'post', JSON.stringify(payload)).then((data) => {
            if (data.httpStatus === 200 && data.json.data.group_list) {
                const col = data.json.data.group_list;
                col.map(column => {
                    var camelCased = column.column_name.replace(/_([a-z])/g, function (g) {
                        return g[1].toUpperCase();
                    });
                    column.tool_tip = definitions[camelCased]
                    return true;
                });
                let userData = data.json.data.user_data;
                userData = userData.map(val => ({...val, isSelected: false}));
                setDataTableList(userData);
                setAllCol(col);
                if(setLoader) {
                    setLoader(false);
                }


            } else {
                setDataTableList([]);
                setLoader(false);
            }
        })
    }

    GetBehaviourStrengthList = (payload, setDataTableList, setAllCol, setLoader, setSelectOptions) => {
        this.b2bApiObj.apiCall('behaviour-report/', 'post', JSON.stringify(payload)).then((data) => {
            if (data.httpStatus === 200) {
                const columnList = data.json.data.group_list;
                let userData = data.json.data.user_data;
                userData = userData.map(val => ({...val, isSelected: false}));
                let selectOptionsArray = columnList.map(column => ({"title": column.group_name}))
                setSelectOptions(selectOptionsArray)
                setDataTableList(userData);
                setAllCol(columnList);
                setLoader(false);
            } else {
                setDataTableList([]);
                setLoader(false);
            }
        })
    }

    getSalesData = (payload, setTableColoumns, setTableData, setcolList,contentfulData, setLoader) => {
        this.b2bApiObj.apiCall('sales-report/', 'post', JSON.stringify(payload)).then((res) => {
            if(res.httpStatus===200 ) {
                let columnList = [];

                const col = res.json.data.group_list
                const tableCols = col[0].columns_list.map(column =>{
                    var camelCased = column.column_name.replace(/_([a-z])/g, function (g) { return g[1].toUpperCase(); });
                    column.tool_tip = contentfulData[camelCased]
                    return column
                });
                setTableColoumns([...tableCols]);
                const userData = res.json.data.user_data;
                setTableData([...userData]);
                col.forEach((val) => {
                    columnList.push({ id: val.group_name, title: val.group_name });
                });
                columnList.push({ id: 'comp&attitude', title: 'Competency & Attitude' });
                setcolList([...columnList]);
                setLoader(false);
            }
            else {
                setLoader(false);
                setTableData([]);
            }}

        )

    }

    GetAdeptAspectsList = (payload, definitions, setDataTableList, setAllCol, setLoader, setSelectOptions) => {
        this.b2bApiObj.apiCall('adept-15/', 'post', JSON.stringify(payload)).then((data) => {
            if (data.httpStatus === 200) {
                const columnList = data.json.data.group_list;
                columnList.map(columnList => {
                    columnList.columns_list.map(column => {
                        var camelCased = column?.column_name.replace(/_([a-z])/g, function (g) {
                            return g[1]?.toUpperCase();
                        });
                        if(definitions) {
                            column.tool_tip = definitions[camelCased]
                        }

                        return true;
                    })
                    return columnList

                });
                let userData = data.json.data.user_data;
                userData = userData.map(val => ({...val, isSelected: false}));
                let selectOptionsArray = columnList.map(column => ({"title": column.group_name}))
                setSelectOptions(selectOptionsArray)
                setDataTableList(userData);
                setAllCol(columnList);
                setLoader(false);
            } else {
                setDataTableList([]);
                setLoader(false);
            }
        })
    }

    GetCompetencePointsList = (payload, definitions, setDataTableList, setAllCol, setLoader, setSelectOptions) => {
        this.b2bApiObj.apiCall('competence-report/', 'post', JSON.stringify(payload)).then((data) => {
            if (data.httpStatus === 200) {
                const columnList = data.json.data.group_list;
                columnList.map(columnList => {
                    columnList.columns_list.map(column => {
                        var camelCased = column?.column_name.replace(/_([a-z])/g, function (g) {
                            return g[1]?.toUpperCase();
                        });
                        if(definitions) {
                            column.tool_tip = definitions[camelCased]
                        }

                        return true;
                    })
                    return columnList

                });
                let userData = data.json.data.user_data;
                userData = userData.map(val => ({...val, isSelected: false}));
                let selectOptionsArray = columnList.map(column => ({"title": column.group_name}))
                setSelectOptions(selectOptionsArray)
                setDataTableList(userData);
                setAllCol(columnList);
                setLoader(false);
            } else {
                setDataTableList([]);
                setLoader(false);
            }
        })
    }

    GetMotivationStrengthList = (payload, definitions, setDataTableList, setAllCol, setLoader, setSelectOptions) => {
        this.b2bApiObj.apiCall('motivation-report/', 'post', JSON.stringify(payload)).then((data) => {
            if (data.httpStatus === 200 && data.json.data.group_list) {
                const columnList = data.json.data.group_list;
                if (definitions && Object.keys(definitions).length > 0) {
                    columnList.map(columnList => {
                        columnList.columns_list.map(column => {
                            var camelCased = column.column_name.replace(/_([a-z])/g, function (g) {
                                return g[1].toUpperCase();
                            });
                            column.tool_tip = definitions[camelCased]
                            return true;
                        })
                        return columnList

                    });
                }
                let userData = data.json.data.user_data;

                userData = userData.map(val => ({...val, isSelected: false}));
                let selectOptionsArray = columnList.map(column => ({"title": column.group_name}))
                setSelectOptions(selectOptionsArray)
                setDataTableList(userData);
                setAllCol(columnList);
                setLoader(false);
            } else {
                setDataTableList([]);
                setLoader(false);
            }
        })
    }

    DeleteCandidateFromTalentPool = (payload, setloader, setVisible) => {
        setloader(true)
        this.b2bApiObj.apiCall('delete-candidate/', 'delete', JSON.stringify(payload)).then((res) => {
            if (res.httpStatus === 200) {
                setloader(false)
                setVisible(false)
            }
        })
    }

    SendAssessmentReminder = (userId, callbackFunction) => {
        this.b2bApiObj.apiCall('assessment-reminder/' + userId, 'POST').then((res) => {
            if (res.httpStatus === 200) {
                callbackFunction(true, res)
            }else {
                callbackFunction(false)
            }
        })
    }

    SaveSearchFilter = (payload, callbackFunction) => {
        this.b2bApiObj.apiCall('search-filter', 'POST', JSON.stringify(payload)).then((res) => {
            if (res.httpStatus === 200) {
                callbackFunction(true, res)
            }else {
                callbackFunction(false)
            }
        })
    }

    GetSearchFilters = (callbackFunction) => {
        this.b2bApiObj.apiCall('search-filter', 'GET').then((res) => {
            if (res.httpStatus === 200) {
                if(res.json && res.json.data){
                    let simplifiedData = res.json.data.map(filter => ({id: filter.id, name: filter.name}));
                    callbackFunction(simplifiedData)
                }
            }else {
                callbackFunction([])
            }
        })
    }

    GetSearchFilterById = (id, callbackFunction) => {
        this.b2bApiObj.apiCall('search-filter/' + id, 'GET').then((res) => {
            if (res.httpStatus === 200) {
                if(res.json && res.json.data){
                    callbackFunction(res.json.data[0].config)
                }
            }else {
                callbackFunction([])
            }
        })
    }

    DeleteSearchFilter = (id, callbackFunction) => {
        this.b2bApiObj.apiCall('search-filter/' + id, 'DELETE').then((res) => {
            if (res.httpStatus === 200) {
                callbackFunction(true)
            }else {
                callbackFunction(false)
            }
        })
    }

    GetTeamSimulatorData = (userIds, callbackFunction) => {
        this.b2bApiObj.apiCall('team-simulator', 'POST', JSON.stringify(userIds)).then((res) => {
            if (res.httpStatus === 200) {
                if(res.json && res.json.data){
                    callbackFunction(res.json.data)
                }
            }else {
                callbackFunction([])
            }
        })
    }

    ResendTeamInvite = (payload, callbackFunction) => {
        this.b2bApiObj.apiCall('resend-team-invite', 'POST', JSON.stringify(payload)).then((res) => {
            if (res.httpStatus === 200) {
                if(res.json){
                    callbackFunction(res.json)
                }
            }else {
                callbackFunction([])
            }
        })
    }

    GetCorporateUserRoles = (callbackFunction) => {
        this.b2bApiObj.apiCall('user-role/', 'GET').then((res) => {
            if (res.httpStatus === 200) {
                if(res.json && res.json.data){
                    callbackFunction(res.json.data)
                }
            }else {
                callbackFunction([])
            }
        })
    }
}
