import { CommonCompetencies, TeamStrengthsChart, SalesProcessStrengths } from './Charts';
import { MotivationCharts } from './MotivationCharts';
// import { useSelector } from 'react-redux';
import { useEffect, useState, memo } from 'react';
import B2BApi from '../../../B2bApi';
// import B2BApiService from '../../../B2bApiService';
import { Select } from "../../../components/formComponents/Inputs";
import loading from '../../../assets/images/icons/loading.gif';
import * as Constants from "../../../constants"

const KeyAnalytics = (props) => {
  // const definitions = useSelector(state => state.contentfulData.all['team strengths']?.fields);
  const coloumnOptions = [
    { id: 'Competency', title: 'Competency' },
    { id: 'Attitude', title: 'Attitude' }

  ]
  let [avgData, setAvgData] = useState({});
  let [teamStrengthData, setTeamStrengthData] = useState();
  let [coloumn, setColoumn] = useState(coloumnOptions[0]);
  let [commonComp, setCommonComp] = useState([]);
  let [topMotivations, setTopMotivations] = useState([]);
  let [lowMotivations, setLowMotivations] = useState([]);
  let [missingComp, setMissingComp] = useState([]);
  let [salesData, setSalesData] = useState([]);
  // let [userData, setUserDataList] = useState();
  // let [columns, setColumns] = useState();

  useEffect(() => {
    if (props.tabActiveKey === Constants.TAB_KEY_ANALYTICS_KEY) {
      getAvgReportsData();
      // GetTeamStrengthListData();
    }
    // eslint-disable-next-line
  }, [props]);

  const getAvgReportsData = () => {

    let obj = new B2BApi();
    let hash_list = [];

    if (props.selectedTeams.length > 0) {
      hash_list = props.selectedTeams.map(x => x.hash);
    }

    obj.apiCall('reports-average/', 'post', JSON.stringify({ hash_list: hash_list })).then((res) => {
      setAvgData(res.json.data);
      setTeamStrengthData(res.json.data.team_strength_average)
      res = res.json.data;
      res.competence_points_average = res.competence_points_average.sort((a,b) => b.average - a.average)
      res.motivation_points_average = res.motivation_points_average.sort((a,b) => b.average - a.average)

      const highComp = res.competence_points_average.slice(0,4);
      const lowComp = res.competence_points_average.slice(-4);

      const top4Motivations = res.motivation_points_average.slice(0,4);
      const bottom4Motivations = res.motivation_points_average.slice(-4);
      setLowMotivations(bottom4Motivations)
      setTopMotivations(top4Motivations)
      setMissingComp(lowComp);
      setCommonComp(highComp);
      setSalesData(res.sales_function_average.Competency);
    }).catch((err) => {

    });
  }

  const onColumnChange = (e) => {
    coloumnOptions.forEach((col) => {
      if (e.target && e.target.value === col.id) {
        setColoumn(col);
        e.target.value === 'Competency' ? setSalesData(avgData.sales_function_average.Competency) : setSalesData(avgData.sales_function_average.Attitude)
      }
    });
  }

  const propsUpdate = (keyVal) => {
      props.activeKey(keyVal.toString())
      props = {...props, tabActiveKey: keyVal.toString() }

  }

  return (
    <>
      {
        avgData?.competence_points_average ?
          <div className="key-analytics-wrapper">

            <div className="analytics-section common-competencies mar-25 bottom">
              <p className="l bold">Work style preferences</p>
              <div className="wrapper" style={{ minHeight: '250px' }}>
                <CommonCompetencies commonComp={commonComp} missingComp={missingComp} />
              </div>
              <br/>
              <br/>
              <p className="individual-analytics-link" onClick={() => propsUpdate(1)}>Individual's Competence Points >></p>
            </div>

            <div className="analytics-section values mar-25 bottom">
              <p className="l bold">Values & Motivation</p>
              <div className="wrapper" style={{ minHeight: '250px' }}>
              <MotivationCharts topMotivations={topMotivations} lowMotivations={lowMotivations}/>
              </div>
              <br/>
              <br/>
              <p className="individual-analytics-link" onClick={() => propsUpdate(2)}>Individual's Motivation Points >></p>
            </div>

            <div className="analytics-section issing-competencies mar-25 bottom">
              <p className="l bold">Team Strengths</p>
              <div className="wrapper" style={{ minHeight: '250px' }}>
                <TeamStrengthsChart teamStrengthData={teamStrengthData} />
              </div>
              <br/>
              <br/>
              <p className="individual-analytics-link" onClick={() => propsUpdate(4)}>Individual's Team Strength Points >></p>
            </div>

            <div className="analytics-section sales-process-strengths mar-25 bottom">
              <span className="salesProcessHeader">
                <p className="l bold">Sales strengths</p>
                <Select
                  id={'coloums'}
                  label='Strength Type'
                  options={coloumnOptions}
                  handleChange={onColumnChange}
                  option={coloumn.title}
                  defaultValue={"Select sales strength type"}
                />
              </span>
              <div className="wrapper" style={{ minHeight: '230px' }}>
                <SalesProcessStrengths salesData={salesData} />
              </div>
              <br/>
              <br/>
              <p className="individual-analytics-link" onClick={() => propsUpdate(3)}>Individual's Sales Strength Points >></p>
            </div>
          </div>
                :
          <div className='page-loading'>
            <img src={loading} alt="Loading..." />
          </div>
      }
    </>
  )
}

export default memo(KeyAnalytics);
