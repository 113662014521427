import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import {Route, Routes, Navigate, useLocation} from 'react-router-dom';

import Login from './pages/auth/Login';
import RecoverPassword from './pages/auth/RecoverPassword';
import RegistrationStage1 from './pages/auth/RegistrationStage1';
import RegistrationStage2 from './pages/auth/RegistrationStage2';
import RegistrationStage3 from './pages/auth/RegistrationStage3';
// import Settings from './pages/auth/Settings';
import Talents from './pages/userPages/Talents';
import FaqPage from './pages/userPages/FaqPage';
import OpenPositions from './pages/userPages/OpenPositions';
import NewOpenPosition from './pages/userPages/NewOpenPosition';
import HiringProcess from './pages/userPages/HiringProcess';
import SalesTeamReview from './pages/userPages/SalesTeamReview';
import TeamAnalytics from './pages/userPages/TeamAnalytics';
import PoolAnalytics from './pages/userPages/PoolAnalytics';
import TeamReview from './pages/userPages/TeamReview';
import Messages from './pages/userPages/Messages';
import Billing from './pages/userPages/Billing';
// import HelpCenter from './pages/userPages/HelpCenter';
import ErrorPage from './pages/errorPage/ErrorPage';
import Onboarding from './pages/userPages/Onboarding';
import SetPassword from './pages/userPages/SetPassword';
import Sidebar from './components/layoutComponents/navigation/Sidebar';
import MobileNav from './components/layoutComponents/navigation/MobileNav';
import * as Constants from './constants'
import UserRoles from "./pages/auth/userRoles.jsx"
import {fetchContentfulData} from './store/actions/contentfulDataActions';
import {useSelector, useDispatch} from 'react-redux';
import {useEffect, useMemo} from "react";

import { ThemeProvider } from '@mui/material';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';

import 'react-toastify/dist/ReactToastify.css';
import B2BApiService from "./B2bApiService";
import { MUITheme } from './MUITheme';

import { logPageView } from './analytics';


function App() {
    const dispatch = useDispatch();
    const location = useLocation();
    const isAuthenticated = useSelector(state => localStorage.getItem(Constants.MAZHR_JWT_KEY) !== null);
    const companyData = useSelector(state => state.company.info);
    const companyIsActive = useSelector(state => state.company.isActive);

    const isProduction = process.env.NODE_ENV === 'production';
    const b2bServiceObj = useMemo(() => {
        return new B2BApiService();
    }, []);

    useEffect(() => {
        logPageView();
        dispatch(fetchContentfulData());
    }, [dispatch]);

    useEffect(() => {
        if (isAuthenticated && !companyData.name) {
            b2bServiceObj.LoadCompanyData();
        }
        // eslint-disable-next-line
    }, [isAuthenticated, companyData.name, b2bServiceObj]);


    // Define an array of restricted paths for logged in user
    const restrictedPaths = [
        '/register',
        '/register-2',
        '/register-3',
        '/set-password',
        '/recover-password'
    ];

    // Redirect authenticated users from restricted paths
    if (isAuthenticated && restrictedPaths.includes(location.pathname)) {
        return <Navigate to="/dashboard" replace/>;
    }

    return (
        <CacheBuster
            currentVersion={packageInfo.version}
            isEnabled={isProduction} //If false, the library is disabled.
            isVerboseMode={false} //If true, the library writes verbose logs to console.
        >
            <ThemeProvider theme={MUITheme}>
                <div className="App">
                    <div className="page">
                        {(isAuthenticated
                            && location.pathname !== "/recover-password"
                            && location.pathname !== "/terms-conditions")
                        &&
                        <>
                            <Sidebar/>
                            <MobileNav/>
                        </>
                        &&
                        <>
                            {window.innerWidth > 992 ?
                                <Sidebar/>
                                :
                                <MobileNav/>
                            }
                        </>
                        }

                        <Routes>
                            {isAuthenticated && !companyIsActive && <Route path="*" element={<Onboarding/>}/>}
                            {isAuthenticated && companyIsActive && <Route path="/" element={<Onboarding/>}/>}
                            {isAuthenticated && companyIsActive &&
                            <Route path="open-positions" element={<OpenPositions/>}/>}
                            {isAuthenticated && companyIsActive &&
                            <Route path="open-positions/:hash" element={<OpenPositions/>}/>}
                            {isAuthenticated && companyIsActive &&
                            <Route path="hiring-process" element={<HiringProcess/>}/>}
                            {isAuthenticated && companyIsActive &&
                            <Route path="new-open-position" element={<NewOpenPosition/>}/>}
                            {isAuthenticated && companyIsActive && <Route path="talents" element={<Talents/>}/>}
                            {isAuthenticated && companyIsActive &&
                            <Route path="sales-team-review" element={<SalesTeamReview/>}/>}
                            {isAuthenticated && companyIsActive &&
                            <Route path="team-analytics" element={<TeamAnalytics/>}/>}
                            {isAuthenticated && companyIsActive &&
                            <Route path="candidate-analytics" element={<PoolAnalytics/>}/>}
                            {isAuthenticated && companyIsActive && <Route path="team-review" element={<TeamReview/>}/>}
                            {isAuthenticated && companyIsActive && <Route path="messages" element={<Messages/>}/>}
                            {isAuthenticated && companyIsActive && <Route path="billing" element={<Billing/>}/>}
                            {/* {isAuthenticated && <Route path="help-center" element={<HelpCenter />} />} */}
                            {isAuthenticated && <Route path="faq-page" element={<FaqPage />} />}
                            {isAuthenticated && companyIsActive && <Route path="talents/:hashParam" element={<Talents/>}/>}
                            {/* {isAuthenticated && companyIsActive && <Route path="team-audit" element={<TeamAudit />} />}*/}
                            {isAuthenticated && <Route path="settings" element={<UserRoles/>}/>}
                            {isAuthenticated && <Route path="*" element={<Onboarding/>}/>}
                            {!isAuthenticated && <Route path="*" element={<Login/>}/>}
                            <Route path="set-password" element={<SetPassword/>}/>
                            <Route path="register" element={<RegistrationStage1/>}/>
                            <Route path="register-2" element={<RegistrationStage2/>}/>
                            <Route path="register-3" element={<RegistrationStage3/>}/>
                            <Route path="recover-password" element={<RecoverPassword/>}/>
                            <Route path="*" element={<ErrorPage/>}/>
                        </Routes>
                    </div>
                </div>
            </ThemeProvider>
        </CacheBuster>
    );
}

export default App;
