import React, { memo, useCallback, useMemo } from 'react';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Button from '../reusableElementsComponents/Button';
import Loading from '../reusableElementsComponents/Loading';
import * as Constants from '../../constants';
import * as Utils from '../../Utilities';
import TabsMenu from "../reusableElementsComponents/TabsMenu";
import NewPositionGeneralEdit from '../../components/newOpenPositionComponents/NewPositionGeneralEdit';
import NewPositionAssessmentsEdit from '../../components/newOpenPositionComponents/NewPositionAssessmentsEdit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SaveIcon from '@mui/icons-material/Save';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import B2BApiService from "../../B2bApiService";

const OpenPositionEdit = ({ data, positionData, formValidate, onSave }) => {
    const b2bServiceObj = useMemo(() => {
        return new B2BApiService();
    }, []);
    const navigate = useNavigate();

    const generalData = {
        title: positionData.title,
        deadline: positionData.deadline ? new Date(positionData.deadline) : null,
        description: positionData.description,
        recruitment_process: positionData.recruitment_process,
        industry: positionData.industry,
        target_profile: positionData.target_profile
    };

    const assessmentData = {
        assessments: positionData.assessments,
        email_list: positionData.email_list?.split(','),
        is_closed: positionData.is_closed
    };

    // Memoize the payloads object
    const payloads = useMemo(() => ({ ...positionData }), [positionData]);

    const handleGeneralData = useCallback((val) => {
        payloads.title = val.title;
        payloads.description = val.description;
        payloads.deadline = val.deadline;
        payloads.industry_id = val.industry_id || payloads.industry.id;
        payloads.target_profile_id = val.target_profile_id || payloads.target_profile.id;
        payloads.recruitment_process = val.recruitment_process || payloads.recruitment_process;
    }, [payloads]);

    const handleAssessmentData = useCallback((val) => {
        payloads.assessments = val.checkeAssesmentIds;
        payloads.is_closed = val.closedPool;
        payloads.email_list = val.emailList?.join(',');
    }, [payloads]);

    const template = {
        tabs: [
            {
                component: <NewPositionGeneralEdit data={generalData} formValidate={formValidate} handleData={handleGeneralData} />,
                sectionTitle: 'general'
            },
            {
                component: <NewPositionAssessmentsEdit data={assessmentData} handleData={handleAssessmentData} />,
                sectionTitle: 'assessments'
            }
        ]
    };

    const textCopied = () => toast.success("Registration URL copied", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const copyJobLink = (hash) => {
        textCopied();
        navigator.clipboard.writeText(`${Constants.MAZHR_APP_URL}?hash=${hash}#register`);
    };

    const savePool = () => {
        const savePoolToast = toast.loading("Updating...");
        const mydate = new Date(payloads.deadline);
        payloads.deadline = `${mydate.getFullYear()}/${mydate.getMonth() + 1}/${mydate.getDate()} 00:00:00`;
        const values = {
            title: payloads.title,
            description: payloads.description,
            deadline: payloads.deadline,
            industry_id: payloads.industry_id?.toString() || payloads.industry.id.toString(),
            target_profile_id: payloads.target_profile_id?.toString() || payloads.target_profile.id.toString(),
            email_list: payloads.emailList || payloads.email_list || '',
            assessments: payloads.checkeAssesmentIds || payloads.assessments,
            is_closed: payloads.closedPool || payloads.is_closed,
            reject_email: payloads.emailList || payloads.reject_email,
            hash: payloads.hash,
            recruitment_process: payloads.recruitment_process,
        };

        b2bServiceObj.SavePool(
            JSON.stringify(values),
            (response) => {
                                if (response.ok && response.httpStatus === 200) {
                                    onSave();
                                    toast.update(savePoolToast, { render: "Edited successfully", type: "success", isLoading: false, autoClose: 2000, closeButton: true, hideProgressBar: true });
                                } else {
                                    toast.update(savePoolToast, { render: "Unable to save edits", type: "error", isLoading: false, autoClose: 2000, closeButton: true, hideProgressBar: true });
                                }
                            },
            () =>{
                                toast.update(savePoolToast, { render: "Unable to save edits", type: "error", isLoading: false, autoClose: 2000, closeButton: true, hideProgressBar: true });
                            })
    };

    if (data) {
        return (
            <div className="open-positions-edit">
                <ToastContainer />
                <div className="basic-info section">
                    <div className="p-0 headers-btn d-flex">
                        <Button
                            btnstyle={'mazhrBtn light-green'}
                            onClick={() => {
                                navigate(`/talents/${positionData.hash}`);
                                localStorage.setItem(Constants.LOCAL_STORAGE_KEY_SEARCH_TYPE, Constants.SEARCH_TYPE_TARGET_POSITION);
                            }}>
                            <VisibilityIcon /> &nbsp; View Pool Candidates
                        </Button>
                        &nbsp;
                        <Button btnstyle={'mazhrBtn light-green'} onClick={() => copyJobLink(positionData.hash)}>
                            <ContentCopyIcon /> &nbsp; Copy Assessment Invite Link
                        </Button>
                    </div>

                    {Utils.currentUserRole() !== Constants.USER_ROLE_TEAMLEAD && Utils.currentUserRole() !== Constants.USER_ROLE_SUPERVISOR && (
                        <div className="calls-to-action p-0">
                            <div className="buttons">
                                <Button btnstyle={'mazhrBtn default-green'} onClick={savePool}>
                                    <SaveIcon /> &nbsp; Save
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
                <TabsMenu template={template} activeKey={() => {}} />
            </div>
        );
    } else {
        return <Loading />;
    }
};

export default memo(OpenPositionEdit);
